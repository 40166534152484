import { window } from 'browser-monads'
import "typeface-montserrat"
import 'typeface-poppins'

import "./src/styles/index.css"

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    return (
      import(`intersection-observer`)
    )
  }
}